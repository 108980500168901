import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    NavLink
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { useLocation } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
function Head(props) {
    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.auth.isAuthenticated);

    const location = useLocation();

    let checkHome = 'home text-bg-white';

    if (location.pathname != '/') {
        checkHome = 'viewPage text-bg-white';
    }
    return (
        <>

            <header className={checkHome}>
                <Navbar  expand="lg">
                    <Container>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Brand href="/"><img width="40px" src="ffa.png" className='logo' /></Navbar.Brand>

                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/#sc_about">About</Nav.Link>
                                <Nav.Link href="/#sc_presale">Presale</Nav.Link>
                                <Nav.Link href="/#sc_roadmap">Roadmap</Nav.Link>
                                <Nav.Link href="/#sc_tokenomics">Tokenomics</Nav.Link>
                                <Nav.Link href="/#sc_rankings">Game rankings</Nav.Link>
                                <Nav.Link href="/#sc_contact">Contact Us</Nav.Link>
                            </Nav>

                        </Navbar.Collapse>
                        <div className="text-end">
                            {isAuth && isAuth == true ? (
                                <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                                    <li><NavLink to="/account" className="nav-link px-2 "><FontAwesomeIcon icon={faUser} /> Account</NavLink></li>
                                </ul>
                            ) : (
                                <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                                    <li><NavLink to="/login" className="nav-link px-2 ">Login</NavLink></li>
                                    <li><NavLink to="/register" className="nav-link px-2 ">Sign-up</NavLink></li>
                                </ul>
                            )
                            }

                        </div>
                    </Container>
                </Navbar>
            </header>

        </>
    );
}


export default Head;