import React from 'react';

function Partners(props) {
    return (
        <>
            <div id="sc_partners">
                <div className="container text-center">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="box-partners ">
                                <div className="box-partners-main">
                                    <div className="box-partners-content">
                                        <h2>FFA Partners</h2>
                                        <div className="list-partner">
                                            <div className="item-partner">
                                                <div className="item-partner-img">
                                                    <a href="https://coinmarketcap.com/" target='_blank'>
                                                        <img src="images/partner3.png" />
                                                    </a>
                                                </div>
                                                <h4>Coinmarketcap</h4>
                                            </div>
                                            {/* end item-partner */}
                                            <div className="item-partner">
                                                <div className="item-partner-img">
                                                    <a href="https://avedex.cc/" target='_blank'>
                                                        <img src="images/partner5.png" />
                                                    </a>
                                                </div>
                                                <h4>Avedex</h4>
                                            </div>
                                            {/* end item-partner */}
                                            <div className="item-partner">
                                                <div className="item-partner-img">
                                                    <a href="https://www.mexc.com/" target='_blank'>
                                                        <img src="images/partner6.png" />
                                                    </a>
                                                </div>
                                                <h4>MEXC</h4>
                                            </div>
                                            {/* end item-partner */}
                                            <div className="item-partner">
                                                <div className="item-partner-img">
                                                    <a href="https://pancakeswap.finance/" target='_blank'>
                                                        <img src="images/partner2.png" />
                                                    </a>
                                                </div>
                                                <h4>PancakeSwap</h4>
                                            </div>
                                            {/* end item-partner */}

                                            <div className="item-partner">
                                                <div className="item-partner-img">
                                                    <a href="https://www.dextools.io/" target='_blank'>
                                                        <img src="images/partner4.png" />
                                                    </a>
                                                </div>
                                                <h4>Dextools</h4>
                                            </div>
                                            {/* end item-partner */}
                                            <div className="item-partner">
                                                <div className="item-partner-img">
                                                    <a href="https://binance.com/" target='_blank'>
                                                        <img src="images/partner1.png" />
                                                    </a>
                                                </div>
                                                <h4>Binance</h4>
                                            </div>
                                            {/* end item-partner */}
                                            <div className="item-partner">
                                                <div className="item-partner-img">
                                                    <a href="https://www.dexview.com/" target='_blank'>
                                                        <img src="images/partner7.png" />
                                                    </a>
                                                </div>
                                                <h4>Dexview</h4>
                                            </div>
                                            {/* end item-partner */}
                                            <div className="item-partner">
                                                <div className="item-partner-img">
                                                    <a href="https://www.certik.com/" target='_blank'>
                                                        <img src="images/partner8.png" />
                                                    </a>
                                                </div>
                                                <h4>Certik</h4>
                                            </div>
                                            {/* end item-partner */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Partners;