import React, { useEffect, useState, useRef } from 'react';

const FRUITS = [
    '/images/apple.png',
    '/images/banana.png',
    '/images/cherry.png',
    '/images/grape.png',
    '/images/watermelon.png',
    '/images/strawberry.png',
    '/images/peach.png',
    '/images/pineapple.png'
];

const createGrid = (size, goalFruits, goalFruitProbabilities) => {
    const grid = [];
    for (let row = 0; row < size; row++) {
        const newRow = [];
        for (let col = 0; col < size; col++) {
            const rand = Math.random();
            let fruit;
            if (rand < goalFruitProbabilities[0]) {
                fruit = goalFruits[0];
            } else if (rand < goalFruitProbabilities[1]) {
                fruit = goalFruits[1];
            } else if (rand < goalFruitProbabilities[2]) {
                fruit = goalFruits[2];
            } else {
                fruit = FRUITS[Math.floor(Math.random() * FRUITS.length)];
            }
            newRow.push(fruit);
        }
        grid.push(newRow);
    }
    return grid;
};

export { createGrid }