import React from 'react';

function About(props) {
    return (
        <>
            <div id="sc_about">
                <div className="container text-center">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <h2 className='title-about'>What is <br></br>Fruit Forest Adventure ?</h2>
                            <div>
                                <p>
                                Fruit Forest Adventure (FFA) offers players a fresh and exciting entertainment experience by combining electronic gaming with blockchain technology. Players will explore a colorful world filled with engaging challenges and quests.
                                </p>
                                <p>
                                FFA leverages blockchain technology to ensure transparency and security in all transactions and activities. This provides peace of mind for players when participating and transacting within the FFA ecosystem.
                                </p>
                                <p>
                                FFA is not just an entertainment game but also offers income-earning opportunities for players and investors. Through in-game activities and special promotional programs, players can earn valuable rewards and increase their income.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default About;