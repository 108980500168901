import React from 'react';

function Tokenomics(props) {
    return (
        <>
            <div id="sc_tokenomics">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='tokenomics-info'>
                                <h2><span>OUR</span> Tokenomics</h2>
                                <h4>$FFA has total supply of 1,000,000,000</h4>
                                <p>Fruit Forest Adventure token serves as the utility token of our platform, enabling users to access premium content, participate in community governance, and redeem exclusive rewards and benefits.</p>
                                <ul>
                                    <li className='tokenomics1'>Liquidity (25%)</li>
                                    <li className='tokenomics2'>Staking (20%)</li>
                                    <li className='tokenomics3'>Community Rewards (20%)</li>
                                    <li className='tokenomics4'>Presale (15%)</li>
                                    <li className='tokenomics5'>Team (10%)</li>
                                    <li className='tokenomics6'>Marketing (10%)</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='tokenomics-img'>
                                <img src="images/chart.png" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Tokenomics;