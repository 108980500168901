import React from 'react';

function Member(props) {
    return (
        <>
            <div id="sc_member">
                <div className="container text-center">
                    <div id="bg_member" className='text-center'>
                        <div>
                            <div className="box-menber">
                                <h3>Total Rewards</h3>
                                <div><span>200,000,000 FFA</span></div>
                            </div>
                        </div>
                        <div>
                            <div className="box-menber box-menber2">
                                <h3>Number of Registered Users</h3>
                                <div><span>1,000</span></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Member;