import React, { useEffect, useState, useRef } from 'react';
import { Howl } from 'howler';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeXmark, faVolumeOff, faStar, faBolt, faArrowsUpDownLeftRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { getPlaygame, setGamePoint, userStartGame, setGameTurn, buyPlayGame, setGamePointOld } from "../../../services/gameService";
import { createGrid } from "./SetupGame";
import { useNavigate } from 'react-router-dom';
import sounds, { stopAllSounds } from './audioManager';
// import { getProfile,postProfile,postProfilePass } from '../../services/profile';
import './StartGame.css';
const FRUITS = [
    '/images/apple.png',
    '/images/banana.png',
    '/images/cherry.png',
    '/images/grape.png',
    '/images/watermelon.png',
    '/images/strawberry.png',
    '/images/peach.png',
    '/images/pineapple.png'
];
const INITIAL_GOAL_FRUIT_COUNT = 1;
const LEVEL_UP_INTERVAL = 40;



const getRandomFruits = (count) => {
    const fruits = [];
    while (fruits.length < count) {
        const fruit = FRUITS[Math.floor(Math.random() * FRUITS.length)];
        if (!fruits.includes(fruit)) {
            fruits.push(fruit);
        }
    }
    return fruits;
};

const StartGame = () => {
    const [oldGame, setOldGame] = useState(0);
    const [checkEndGame, setCheckEndGame] = useState(0);
    const [playGame, setPlayGame] = useState(0);
    const [size, setSize] = useState(6);
    const [grid, setGrid] = useState(() => createGrid(size, getRandomFruits(3), [0.3, 0.2, 0.1]));
    const [selected, setSelected] = useState(null);
    const [score, setScore] = useState(0);
    const [scoreLevel, setScoreLevel] = useState(0);
    const [scoreNext, setScoreNext] = useState(3);
    const [moves, setMoves] = useState(20);
    const [gameOver, setGameOver] = useState(false);
    const [round, setRound] = useState(1);
    const [goalFruits, setGoalFruits] = useState(getRandomFruits(7));
    const [goalFruitCounts, setGoalFruitCounts] = useState(goalFruits.reduce((acc, fruit) => {
        acc[fruit] = INITIAL_GOAL_FRUIT_COUNT;
        return acc;
    }, {}));
    const [collectedFruitCounts, setCollectedFruitCounts] = useState(goalFruits.reduce((acc, fruit) => {
        acc[fruit] = 0;
        return acc;
    }, {}));
    const [level, setLevel] = useState(1);
    const [gameStarted, setGameStarted] = useState(false);
    const [showWinMessage, setShowWinMessage] = useState(true);
    const [initialGoalCounts, setInitialGoalCounts] = useState(goalFruits.reduce((acc, fruit) => {
        acc[fruit] = INITIAL_GOAL_FRUIT_COUNT;
        return acc;
    }, {}));
    const [nextLevelScore, setNextLevelScore] = useState(LEVEL_UP_INTERVAL); // Điểm cần đạt để lên level
    const [isMusicPlaying, setIsMusicPlaying] = useState(true);

    // const [sound, setSound] = useState(null);

    useEffect(() => {

        loadPlayGame();
    }, []);

    const loadPlayGame = async () => {
        const data = await getPlaygame();
        if (data && data.playgame) {
            setPlayGame(data.playgame);
        }

    }

    const playCoinSound = () => {
        if (sounds && sounds.coinsSound && isMusicPlaying) {
            sounds.coinsSound.play();
        }
    };

    const playStartSound = () => {
        if (sounds && sounds.startSound && isMusicPlaying) {
            sounds.startSound.play();
        }
    };

    const playGameOverSound = () => {
        if (sounds && sounds.gameOverSound && isMusicPlaying) {
            sounds.gameOverSound.play();
        }
    };

    const playBtnOverSound = () => {
        if (sounds && sounds.btnOverSound && isMusicPlaying) {
            sounds.btnOverSound.play();
        }
    };

    const playWinSound = () => {
        if (sounds && sounds.winSound && isMusicPlaying) {
            sounds.winSound.play();
        }
    };

    const setPointGameOver = async () => {
       
        if (checkEndGame == 0) {
            setCheckEndGame(1);
           
            if (oldGame == 0) {
               
                let endgame = await setGamePoint(score, level);
                if (endgame.status == '999') {
                    setOldGame(endgame.id);
                }
            } else {
                await setGamePointOld(score, level, oldGame);
                
            }

        }
    }
    useEffect(() => {


        if (moves <= 0) {
            setGameOver(true);
            playGameOverSound();
            setPointGameOver();
            return;
        }

        const checkMatches = () => {
            let matches = [];
            let fruitCounts = {};

            for (let row = 0; row < size; row++) {
                for (let col = 0; col < size; col++) {
                    const fruit = grid[row][col];
                    if (fruit) {
                        // Check horizontal
                        let match = [];
                        for (let c = col; c < size && grid[row][c] === fruit; c++) {
                            match.push([row, c]);
                        }
                        if (match.length >= 3) {
                            matches = matches.concat(match);
                            if (!fruitCounts[fruit]) fruitCounts[fruit] = 0;
                            if (goalFruits.includes(fruit)) fruitCounts[fruit] += match.length;
                        }

                        // Check vertical
                        match = [];
                        for (let r = row; r < size && grid[r][col] === fruit; r++) {
                            match.push([r, col]);
                        }
                        if (match.length >= 3) {
                            matches = matches.concat(match);
                            if (!fruitCounts[fruit]) fruitCounts[fruit] = 0;
                            if (goalFruits.includes(fruit)) fruitCounts[fruit] += match.length;
                        }
                    }
                }
            }

            if (matches.length > 0) {
                // Remove matches and update score
                const newGrid = grid.map(r => [...r]);
                const uniqueMatches = Array.from(new Set(matches.map(JSON.stringify)), JSON.parse);
                const matchCount = uniqueMatches.length;
                if (moves < 20) {
                    playCoinSound();
                    setScore(prevScore => prevScore + matchCount);
                    setScoreLevel(prevScore => prevScore + matchCount);
                }


              
                uniqueMatches.forEach(([r, c]) => {
                    const fruit = grid[r][c];
                    if (goalFruits.includes(fruit)) {
                        setCollectedFruitCounts(prevCounts => ({
                            ...prevCounts,
                            [fruit]: (prevCounts[fruit] || 0) + 1
                        }));
                    }
                    newGrid[r][c] = null; 
                });

                
                for (let col = 0; col < size; col++) {
                    let emptySpaces = 0;
                    for (let row = size - 1; row >= 0; row--) {
                        if (newGrid[row][col] === null) {
                            emptySpaces++;
                        } else if (emptySpaces > 0) {
                            newGrid[row + emptySpaces][col] = newGrid[row][col];
                            newGrid[row][col] = null;
                        }
                    }
                    for (let i = 0; i < emptySpaces; i++) {
                        newGrid[i][col] = FRUITS[Math.floor(Math.random() * FRUITS.length)];
                    }
                }

                setGrid(newGrid);

                // Check if goals are reached
                if (scoreLevel >= nextLevelScore) {
                    setShowWinMessage(true);
                } else {

                }
            }
            checkGoals();
        };

        checkMatches();
    }, [grid, setScoreLevel, setNextLevelScore]);

    const checkGoals = async () => {
        if (scoreLevel >= nextLevelScore) {
            setShowWinMessage(true);
            playWinSound();
            let additionalPoints = 0;
            if (moves > 0) {
                additionalPoints = moves * INITIAL_GOAL_FRUIT_COUNT;
            }

            setScore(prevScore => prevScore + additionalPoints);

        } else {
        }
    };

    useEffect(() => {
        if (showWinMessage) return;
        checkGoals();
    }, [collectedFruitCounts, goalFruitCounts, goalFruits, showWinMessage, setScoreLevel, setNextLevelScore]);

    const handleCellClick = (row, col) => {
        if (gameOver || showWinMessage) return;
        playBtnOverSound();
        if (selected) {

            const [prevRow, prevCol] = selected;
            if (Math.abs(prevRow - row) + Math.abs(prevCol - col) === 1) {
                // Swap logic
                const newGrid = grid.map(r => [...r]);
                [newGrid[row][col], newGrid[prevRow][prevCol]] = [newGrid[prevRow][prevCol], newGrid[row][col]];
                setGrid(newGrid);
                setSelected(null);
                checkGoals();
                setMoves(prevMoves => prevMoves - 1);

            } else {
                setSelected([row, col]);
            }
        } else {
            setSelected([row, col]);
        }
    };

    const startGame = async () => {
        await userStartGame();
        setGameOver(false);
        setShowWinMessage(false);
        setScore(0);
        setMoves(20);
        setRound(1);
        setLevel(1);
        setNextLevelScore(LEVEL_UP_INTERVAL);
        const newGoalFruits = getRandomFruits(3);
        setGoalFruits(newGoalFruits);
        setGoalFruitCounts(newGoalFruits.reduce((acc, fruit) => {
            acc[fruit] = INITIAL_GOAL_FRUIT_COUNT;
            return acc;
        }, {}));
        setCollectedFruitCounts(newGoalFruits.reduce((acc, fruit) => {
            acc[fruit] = 0;
            return acc;
        }, {}));
        setInitialGoalCounts(newGoalFruits.reduce((acc, fruit) => {
            acc[fruit] = INITIAL_GOAL_FRUIT_COUNT;
            return acc;
        }, {}));
        setGrid(createGrid(size, newGoalFruits, [0.3, 0.2, 0.1]), FRUITS);
        setGameStarted(true);
        setScore(0);
        playStartSound();
    };

    const restartGame = async () => {
        setPlayGame(prevScore => prevScore - 1);
        setGameStarted(false);
        setLevel(1);
        setGameOver(false);
        stopAllSounds();
        setScore(0);
        setMoves(1);
        setRound(1);
        setLevel(1);
        setCheckEndGame(0);

    };

    const nextLevel = () => {

        setShowWinMessage(false);
        setScoreLevel(0);
        setLevel(prevLevel => prevLevel + 1);
        let pointTemp = LEVEL_UP_INTERVAL + (level * scoreNext);
        setNextLevelScore(pointTemp);

        setScoreNext(prevScore => prevScore + 3);



        // Reset moves and update goal
        setMoves(20);
        setRound(prevRound => prevRound + 1);
        const newGoalFruits = getRandomFruits(3);
        setGoalFruits(newGoalFruits);
        setGoalFruitCounts(newGoalFruits.reduce((acc, fruit) => {
            acc[fruit] = INITIAL_GOAL_FRUIT_COUNT;
            return acc;
        }, {}));
        setCollectedFruitCounts(newGoalFruits.reduce((acc, fruit) => {
            acc[fruit] = 0;
            return acc;
        }, {}));
        setInitialGoalCounts(newGoalFruits.reduce((acc, fruit) => {
            acc[fruit] = INITIAL_GOAL_FRUIT_COUNT;
            return acc;
        }, {}));
        setGrid(createGrid(size, newGoalFruits, [0.3, 0.2, 0.1]));

    };
    const toggleMusic = () => {
        setIsMusicPlaying(prev => !prev);
        if (isMusicPlaying) {
            if (sounds && sounds.startSound) {
                sounds.startSound.stop();
            }
            if (sounds && sounds.gameOverSound) {
                sounds.gameOverSound.stop();
            }
        } else {
            if (sounds && sounds.startSound) {
                sounds.startSound.play();
            }

        }
    };

    const turnGame = async () => {
        const data = await setGameTurn();
        if (data.status == '999') {
            setMoves(5);
            setShowWinMessage(false);
            setGameStarted(true);
            setGameOver(false);
            toast.success('You have 5 more moves.');
            setCheckEndGame(0);
        } else {
            toast.error('Not enough points please convert more points.');
        }
    }

    const buyGame = async () => {
        const data = await buyPlayGame();
        if (data.status == '999') {
            setGameOver(false);
            setShowWinMessage(false);
            setScore(0);
            setMoves(20);
            setRound(1);
            setLevel(1);
            setNextLevelScore(LEVEL_UP_INTERVAL);
            const newGoalFruits = getRandomFruits(3);
            setGoalFruits(newGoalFruits);
            setGoalFruitCounts(newGoalFruits.reduce((acc, fruit) => {
                acc[fruit] = INITIAL_GOAL_FRUIT_COUNT;
                return acc;
            }, {}));
            setCollectedFruitCounts(newGoalFruits.reduce((acc, fruit) => {
                acc[fruit] = 0;
                return acc;
            }, {}));
            setInitialGoalCounts(newGoalFruits.reduce((acc, fruit) => {
                acc[fruit] = INITIAL_GOAL_FRUIT_COUNT;
                return acc;
            }, {}));
            setGrid(createGrid(size, newGoalFruits, [0.3, 0.2, 0.1]), FRUITS);
            setGameStarted(true);
            setScore(0);
            playStartSound();

            toast.success('Purchased play successfully');

        } else {
            toast.error('Insufficient FFA coins. Please top up FFA coins.');

        }

    }

    let navigate = useNavigate();
    const toShop = () => {
        navigate('/shop');
    };
    return (
        <div className="app-game">

            {!gameStarted && (
                <>
                    {playGame > 0 ? (
                        <><button className='btn-start-game' onClick={startGame}>Start Game <span>({playGame})</span></button></>

                    ) : (<><button className='btn-start-game btn-buy-game' onClick={buyGame}>Buy more plays <span>( 1 FFA )</span></button></>)

                    }

                </>

            )}
            {gameStarted && (
                <>
                    <div>
                        <div className="game-head">
                            <div className="game-top-poin">
                                <div className="level"><FontAwesomeIcon icon={faStar} /> {level}</div>
                                <div className="score"><FontAwesomeIcon icon={faBolt} /> {score}</div>
                                <div className="moves"><FontAwesomeIcon icon={faArrowsUpDownLeftRight} /> {moves}</div>
                            </div>
                            <div className="mute"><button className='btn-mute' onClick={toggleMusic}> <FontAwesomeIcon icon={isMusicPlaying ? faVolumeXmark : faVolumeOff} /> </button></div>
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    style={{
                                        width: `${Math.min((scoreLevel / nextLevelScore) * 100, 100)}%`,
                                        backgroundColor: '#FF5722',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="main-view-play-game">
                            <div className="grid">
                                {grid.map((row, rowIndex) =>
                                    row.map((cell, colIndex) => (
                                        <div
                                            key={`${rowIndex}-${colIndex}`}
                                            className={`cell ${selected && selected[0] === rowIndex && selected[1] === colIndex ? 'selected' : ''}`}
                                            onClick={() => handleCellClick(rowIndex, colIndex)}
                                        >
                                            <img src={cell} alt="fruit" className="fruit-icon" />
                                        </div>
                                    ))
                                )}
                            </div>
                            {gameOver && (
                                <div className="message message-gameover">
                                    <div className="main-message-next">
                                        <h3>Game Over</h3>
                                        <div className="text-level">Level <span>{level}</span></div>
                                        <div className="text-level-icon"><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></div>
                                        <div className="text-score"><FontAwesomeIcon icon={faBolt} /> Score: <span>{score}</span></div>
                                        <div className="main-message-btn">
                                            <button className='btn-game-replay' onClick={restartGame}><FontAwesomeIcon icon={faXmark} /></button>
                                            <button className='btn-turn-game' onClick={turnGame}><span><FontAwesomeIcon icon={faArrowsUpDownLeftRight} /> 5</span> Play on <FontAwesomeIcon icon={faBolt} />50</button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {showWinMessage && (
                                <div className="message message-next">
                                    <div className="main-message-next">
                                        <h3>Level Completed</h3>
                                        <div className="text-level">Level <span>{level}</span></div>
                                        <div className="text-level-icon"><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></div>
                                        <div className="text-score"><FontAwesomeIcon icon={faBolt} /> Score: <span>{score}</span></div>
                                        <button className="btn-next-game" onClick={nextLevel}>Next</button>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default StartGame;
