import React from 'react';

function Roadmap(props) {
    return (
        <>
            <div id="sc_roadmap">
                <div id="bg_roadmap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className='text-center title-h'>OUR Roadmap</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-11">
                                <div className="main-timeline">
                                    <div className="timeline">
                                        <div href="#" className="timeline-content">
                                            <h3 className="title">Launch</h3>
                                            <div className="description">
                                                <ul>
                                                    <li>Complete the development of the main game, start pre-launch marketing campaigns.</li>
                                                    <li>Official game launch and start of the presale.</li>
                                                    <li>Expand the initial player and investor community, organize events and promotional programs to enhance engagement.</li>
                                                    <li>Complete the presale, list FFA coins on major exchanges.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* timeline*/}
                                    <div className="timeline">
                                        <div href="#" className="timeline-content">
                                            <h3 className="title">Expansion and Growth</h3>
                                            <div className="description">
                                                <ul>
                                                    <li>Integrate new features into the game, expand the game ecosystem with mini-games and special events.</li>
                                                    <li>Partner with strategic players in the gaming and blockchain industries, enhance marketing campaigns to expand the global user community.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* timeline*/}
                                    <div className="timeline">
                                        <div href="#" className="timeline-content">
                                            <h3 className="title">Stabilization and Sustainable Development</h3>
                                            <div className="description">
                                                <ul>
                                                    <li>Develop additional games within the FFA ecosystem, improve user experience and ensure platform stability.</li>
                                                    <li>Expand the global community, achieve stability in the value of FFA coins, and continue to improve and develop the platform based on user feedback.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* timeline*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Roadmap;