import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {registerUserServicer} from '../../services/userService';
function Register(props) {

    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    let navigate = useNavigate();
    const btnLinkLogin = () => {
        navigate('/login');
    }

    const isValidInputs = () => {
        let check = 0;
        if (!email) {
            toast.error('Email is required');
            check = 1;
        }

        if (!fullName) {
            toast.error('FullName is required');
            check = 1;
        }

        if (!userName) {
            toast.error('Username is required');
            check = 1;
        }

        if (!password) {
            toast.error('Password is required');
           check = 1;
        }

        if(check == 1){
            return false;
        }

        const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(!validEmailRegex.test(email)){
            toast.error('Please enter a vaild email address');
            return false;
        }

        const validPasswordRegex = /^.{8,32}$/;
        if(!validPasswordRegex.test(password)){
            toast.error('Please enter a valid password (8 to 32 characters)');
            return false;
        }

        return true;
    }

    const handleRegister = async  () => {
              
        if (isValidInputs()) {    
            let data = await registerUserServicer(fullName, email, userName, password);                   
            
            if(data.status == '999'){
                toast.success(data.msg);
                navigate('/login');
                setEmail("");
                setFullName("");
                setUserName("");
                setPassword("");
                return false;
            }else{
                toast.error(data.msg);
                return false;
            }
        }
    }

    return (
        <>
            <div className='register-container'>
                <div className='container'>
                    <div className='row'>
                        <div className="form-signin w-100 m-auto">
                            <form>
                                <h1 className="h3 mb-3 fw-normal">Sign up</h1>
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="fullname" placeholder="Full name" value={fullName} onChange={(event) => setFullName(event.target.value)} />
                                    <label htmlFor="fullname">FullName</label>
                                </div>
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="userlogin" placeholder="Userlogin" value={userName} onChange={(event) => setUserName(event.target.value)} />
                                    <label htmlFor="userlogin">UserName</label>
                                </div>
                                <div className="form-floating">
                                    <input type="email" className="form-control" id="email" placeholder="name@example.com" value={email} onChange={(event) => setEmail(event.target.value)} />
                                    <label htmlFor="email">Email</label>
                                </div>
                                <div className="form-floating">
                                    <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} />
                                    <label htmlFor="floatingPassword">Password</label>
                                </div>
                                <button className="btn btn-primary w-100 py-2 btn-register1" type="button" onClick={() => handleRegister()}>Register</button>
                                <button className="btn btn-dran w-100 py-2 btn-register2" type="button" onClick={btnLinkLogin}>Login</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;