import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar,faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { getTopGame } from '../.././/services/gameService';
function Rankings(props) {
    const [users, setUsers] = useState([]);
    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        const data = await getTopGame();
        if (data && data.status == '999') {
            setUsers(data.list);
        }
    }

    

    return (
        <>
            <div id="sc_rankings">
                <div id="bg_rankings">
                    <div className="container text-center">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 text-left">
                                <div className="box-leaderboard">
                                    <h4>Leaderboard Rewards for Top Players</h4>
                                    <p>Players who achieve the highest rankings in the game will have the opportunity to win amazing rewards:</p>
                                    <ul>
                                        <li>1st Place: $3,000 USD</li>
                                        <li>2nd Place: $2,000 USD</li>
                                        <li>3rd Place: $1,000 USD</li>
                                        <li>4th to 10th Place: $100 USD each</li>
                                    </ul>
                                    <p>Join the competition and climb the leaderboard to claim these fantastic prizes!</p>

                                    {/* <button type='button' className="btn-coming-soon">Coming soon</button> */}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="inner">
                                    <div className="box-rankings">
                                        <h3><span>Ranking</span></h3>
                                        <div className="list-ranking-item">
                                            {
                                                
                                                users.map((user,index) => (

                                                <div key={index} className="ranking-item">
                                                    <div className="ranking-item-stt">
                                                        <span>{index + 1}</span>
                                                    </div>
                                                    <div className="ranking-item-name">                                                       
                                                        <span>{user.User.displayName}</span>
                                                    </div>
                                                    <div className="ranking-item-point">
                                                        <span><FontAwesomeIcon icon={faStar} /> {user.point}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Rankings;