import React, { useState,useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {loginUserServicer} from '../../services/userService';
import { useDispatch, useSelector } from 'react-redux';
import {setAuth} from '../../redux/slices/authSlice';
function Login(props) {
    const dispatch = useDispatch(); 
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    let navigate = useNavigate();
    const btnLinkRegister = () => {
        navigate('/register');
    }
    
    const isValidInputs = () => {
        let check = 0;      

        if (!userName) {
            toast.error('Username is required');
            check = 1;
        }

        if (!password) {
            toast.error('Password is required');
           check = 1;
        }

        if(check == 1){
            return false;
        }

       

        const validPasswordRegex = /^.{8,32}$/;
        if(!validPasswordRegex.test(password)){
            toast.error('Please enter a valid password (8 to 32 characters)');
            return false;
        }

        return true;
    }
    const handleLogin = async() => {        
        if (isValidInputs()) {    
            let data = await loginUserServicer(userName, password);                   
           
            if(data.status == '999'){
                toast.success(data.msg);
                let userData = {
                    displayName: data.displayName,
                    email: data.email,
                    level :data.level,
                    id :data.id,
                };
                dispatch(setAuth({ user: userData, token: data.access_token,level :data.level }));
                let authData = {
                    user: userData,
                    isAuthenticated: true,
                    token: data.access_token,
                    isError: false,
                    level:data.level,
                  }
                localStorage.setItem('auth', JSON.stringify(authData));
                localStorage.setItem('point', data.point);
                localStorage.setItem('pointCoin', data.pointCoin);
                navigate('/account');              
                return false;
            }else{
                toast.error(data.msg);
                return false;
            }
        }
    }

    return (
        <>
            <div className='login-container'>
                <div className='container'>
                    <div className='row'>
                        <div className="form-signin w-100 m-auto">
                            <form>
                                <h1 className="h3 mb-3 fw-normal">Sign in</h1>
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="UserName" placeholder="Username" value={userName} onChange={(event) => setUserName(event.target.value)} />
                                    <label htmlFor="UserName">Username</label>
                                </div>
                                <div className="form-floating">
                                    <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} />
                                    <label htmlFor="floatingPassword">Password</label>
                                </div>                                
                                <button className="btn btn-primary w-100 py-2 btn-login1" type="button" onClick={() => handleLogin()}>Sign in</button>
                                <button className="btn w-100 py-2 btn-login2" type="button" onClick={() => btnLinkRegister()}>Register</button>
                                                          
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;